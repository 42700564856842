import gql from 'graphql-tag';

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    products
    productsQuantity
    total {
      currency
      amount
      localized
    }
    totalInUsd {
      currency
      amount
      localized
    }
    basetotal {
      currency
      amount
      localized
    }
    subtotal {
      amount
      localized
    }
    methodDiscount {
      currency
      amount
      localized
    }
    method: paymentMethod {
      id
      name
      label
      discountAmount
      image {
        url
      }
    }
    methodFee {
      currency
      amount
      localized
    }
    voucherDiscount {
      currency
      amount
      localized
    }
    token
    currency
    description
    fullDescription
    voucherCode
    discountName
    discountAmount
    extraGoldAmount
    selectedMethodFee {
      localized
      amount
    }
    provider
    type
    productId
    discount {
      localized
      amount
    }
    quantity
    totalQuantity
    id
    isBlocked
    rsn
    email
    loyaltyPoints(showPossiblePoints: true)
    productType
    productName
    user {
      loyaltyDetails {
        currentLevel {
          image(size: "38x38") {
            url
          }
          name
        }
      }
    }
    extraData
    extraLoyaltyPointsAmount
  }
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    id
    number
    variantName
    products
    productName
    #description(full:true)
    description
    status
    loyaltyPoints(showPossiblePoints: true)
    rsn
    userEmail
    productId
    variantId
    quantity
    productType
    totalQuantity: quantity(extraGold: true)
    isCasePrize
    instructions
    note
    user {
      loyaltyDetails {
        currentLevel {
          image(size: "38x38") {
            url
          }
          name
        }
      }
    }
    payment {
      token
      currency
      chargeStatus
      total {
        amount
        currency
        localized
      }
      subtotal {
        amount
        currency
        localized
      }
      methodFee {
        amount
        currency
        localized
      }
      discountAmount
      voucher {
        code
      }
      discount {
        amount
        currency
        localized
      }
      method {
        name
        label
        instructions
        image(size: "300x120") {
          url
        }
      }
    }
    provider
    type
  }
`;

export const OrderQuery = gql`
  ${OrderFragment}
  query Order($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
`;

export const CheckoutQuery = gql`
  ${CheckoutFragment}
  query Checkout($token: UUID) {
    checkout(token: $token) {
      ...CheckoutFragment
    }
  }
`;

export const SuccessPageCheckoutDetailsQuery = gql`
  ${OrderFragment}
  ${CheckoutFragment}
  query SuccessPageCheckoutDetails($token: UUID) {
    checkout(token: $token) {
      ...CheckoutFragment
      order {
        ...OrderFragment
      }
    }
  }
`;

export const SuccessPageOrderDetailsQuery = gql`
  ${OrderFragment}
  query SuccessPageOrderDetails($token: UUID) {
    order(token: $token) {
      ...OrderFragment
      extraData
      extraLoyaltyPointsAmount
      payment {
        gateway {
          slug
        }
      }
    }
  }
`;

export const PaymentStatusQuery = gql`
  query PaymentStatus($token: UUID) {
    order(token: $token) {
      id
      productType
      checkout {
        token
      }
      payment {
        chargeStatus
        gateway {
          slug
        }
      }
    }
  }
`;

export const VerifyPaymentStatusQuery = gql`
  query VerifyPaymentStatus($token: UUID) {
    checkout(token: $token) {
      paymentStatus
      productType
    }
  }
`;

export const ActivePaymentQuery = gql`
  query ActivePayment {
    activePayment @client(always: true) {
      id
      code
      label
      __typename
    }
  }
`;

export const OrderSerialsVariantsQuery = gql`
  query OrderSerialsVariants($orderId: ID!) {
    orderSerialsVariants(orderId: $orderId) {
      key
    }
  }
`;
