// eslint-disable-next-line filenames/match-exported
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ApolloProvider} from '@apollo/react-hooks';

import theme from 'src/themes/theme';
import {useApollo} from 'apollo/client';

export default function MyApp(props) {
  const {Component, pageProps} = props;
  let initialApolloState = null;

  if (pageProps && pageProps.initialApolloState) {
    initialApolloState = {...pageProps.initialApolloState};
  }

  const apolloClient = useApollo(initialApolloState);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <title>Probemas</title>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1'
        />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </ApolloProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};
