import gql from 'graphql-tag';
import { CheckoutFragment, OrderFragment } from './queries';

export const CheckoutDeleteMutation = gql`
  mutation CheckoutDelete($id: ID!) {
    checkoutDelete(id: $id) {
      errors {
        field
        message
      }
    }
  }
`;

export const CheckoutUpdateMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutUpdate($id: ID!, $input: CheckoutUpdateInput!) {
    checkoutUpdate(id: $id, input: $input) {
      errors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CheckoutUpdateVoucherMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutUpdateVoucher($checkoutId: ID!, $voucherCode: String) {
    checkoutUpdateVoucher(checkoutId: $checkoutId, voucherCode: $voucherCode) {
      errors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CheckoutCompleteMutation = gql`
  ${OrderFragment}
  mutation checkoutComplete($checkoutId: ID!) {
    checkoutComplete(checkoutId: $checkoutId) {
      errors {
        field
        message
      }
      redirectUrl
      order {
        ...OrderFragment
      }
    }
  }
`;

export const CheckoutPrizeCompleteMutation = gql`
  mutation CheckoutPrizeComplete($checkoutId: ID!) {
    checkoutPrizeComplete(checkoutId: $checkoutId) {
      errors {
        field
        message
      }
      order {
        id
        productId
      }
    }
  }
`;
