import gql from 'graphql-tag';
import { UserFragment } from '../../views/Header/mutations';

export const IsLoggedInQuery = gql`
  query isLoggedIn {
    isLoggedIn @client(always: true)
  }
`;

export const CustomerQuery = gql`
  ${UserFragment}
  query Customer {
    customer @client {
      ...UserFragment
    }
  }
`;

export const MeQuery = gql`
  ${UserFragment}
  query Me {
    me {
      ...UserFragment
    }
  }
`;
